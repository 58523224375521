import React from 'react';

import EntryListItem from './entry-list-item.js';

import './entry-list.scss';

function EntryMonth(props) {
    var { deleteEntry, entryMonths, selected, setSelected } = props;

    return (
        <div className='entryList'>
            {Object.keys(entryMonths).map((monthName) => (
                <div className='entryMonth' key={monthName}>
                    <div className='monthName'>{monthName}</div>
                    {entryMonths[monthName].map((entry) => (
                        <EntryListItem
                            deleteEntry={deleteEntry}
                            entry={entry}
                            key={entry.id}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

export default EntryMonth;
