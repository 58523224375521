import React, { useEffect, useState } from 'react';
// import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { /*Navigate,*/ Routes, Route } from 'react-router-dom';

import { ajax } from 'svs-utils/web';
import { AppLayout, useLogin } from 'svs-utils/react';

// import { ajax } from './utils/ajax-utils';
import { Home } from './components/index.js';

// const exactPaths = [
//     { path: '/home', component: Home },
//     { path: '/', component: Home },
//     { path: `/entry/:monthName/:entryId`, component: Home },
//     { path: `/e/:monthName/:entryId`, component: Home },
// ];

function AppRouter(props) {
    var [entryMonths, setEntryMonths] = useState({});

    var login = useLogin();

    useEffect(() => {
        getEntries();
    }, []);

    var getEntries = async () => {
        var results = await ajax({ endPoint: '/getEntries' });

        if (results.result) {
            var entryMonths = results.entries.reduce(
                (acc, entry) => ({
                    ...acc,
                    [entry.monthName]: [
                        ...(acc[entry.monthName] || []),
                        { ...entry, createdOn: new Date(entry.createdOn), modifiedOn: new Date(entry.modifiedOn) }
                    ]
                }),
                {},
            );

            setEntryMonths(entryMonths);
        }
    };

    var componentProps = {
        entryMonths,
        logout: login.logout,
        updateEntryMonths: (newEntryMonths) => setEntryMonths({ ...newEntryMonths }),
    };

    return (
        <Routes>
            <Route path='/' element={<AppLayout colorMode='darkMode' />}>
                <Route path='home' element={<Home {...componentProps} />} />
                <Route path='entry' element={<Home {...componentProps} />}>
                    <Route path=':monthName' element={<Home {...componentProps} />}>
                        <Route path=':entryId' element={<Home {...componentProps} />}></Route>
                    </Route>
                </Route>
                <Route path='e' element={<Home {...componentProps} />}>
                    <Route path=':monthName' element={<Home {...componentProps} />}>
                        <Route path=':entryId' element={<Home {...componentProps} />}></Route>
                    </Route>
                </Route>
                <Route index element={<Home {...componentProps} />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
