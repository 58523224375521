import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import { LoginProvider } from 'svs-utils/react';

import { GlobalStateProvider } from './utils/reactUtils.js';
import AppRouter from './router.js';
import * as serviceWorker from './serviceWorker.js';
import './index.scss';

var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <LoginProvider>
                <GlobalStateProvider>
                    <AppRouter />
                </GlobalStateProvider>
            </LoginProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
