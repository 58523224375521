import React from 'react';

import classnames from 'classnames';
import dateFormat from 'dateformat';

import { NoContextMenu } from 'svs-utils/react';

import './entry-list-item.scss';

function EntryListItem(props) {
    var { deleteEntry, entry: { createdOn, id, monthName, text, title }, selected, setSelected } = props;

    var entryItemClass = classnames('entryListItem', { selected: selected === id });
    var contextMenuGroups = [
        [
            { name: 'Delete Entry', onClick: () => deleteEntry(monthName, id) },
        ]
    ];

    return (
        <NoContextMenu groups={contextMenuGroups}>
            <div className={entryItemClass} onClick={() => setSelected(id)}>
                <div className='dateDisplay'>
                    <div className='dayName'>{dateFormat(createdOn, 'ddd').toUpperCase()}</div>
                    <div className='dayNumber'>{dateFormat(createdOn, 'dd')}</div>
                </div>
                <div className='entryInfo'>
                    <div className='title'>{title}</div>
                    <div className='text'>{text}</div>
                    <div className='createdDate'>{dateFormat(createdOn, 'hh:MM TT Z')}</div>
                </div>
            </div>
        </NoContextMenu>
    );
}

export default EntryListItem;
