import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { confirmProm } from 'svs-utils/react';

import { CurrentEntry, EntryList } from './index.js';
import './home.scss';

function Home(props) {
    var { entryMonths, logout, updateEntryMonths } = props;

    var navigate = useNavigate();
    var urlParams = useParams();
    var saveTimeout = useRef(null);

    var [savingMessage, setSavingMessage] = useState('');
    var [selected, setSelected] = useState(parseInt(urlParams.entryId));

    var getSelectedEntry = () => {
        if (selected) {
            return Object.keys(entryMonths).reduce((acc, monthName) => {
                if (acc) {
                    return acc;
                } else {
                    return entryMonths[monthName].find((entry) => entry.id === selected) || null;
                }
            }, null);
        } else {
            return null;
        }
    };

    var setSelectedEntry = (entryId) => {
        if (selected === entryId || !entryId) {
            setSelected(null);
            navigate('/');
        } else {
            var entry = Object.keys(entryMonths).reduce(
                (acc, monthName) =>  acc || entryMonths[monthName].find((entry) => entry.id === entryId) || null,
                null
            );

            setSelected(entryId);
            navigate(`/e/${entry.monthName.replace(' ', '-').toLowerCase()}/${entryId}`);
        }
        setSavingMessage('');
    };

    var updateSelectedEntry = (title, text) => {
        var selectedEntry = getSelectedEntry();
        var { id, monthName } = selectedEntry;

        var newEntryMonths = { ...entryMonths };
        var entryIndex = newEntryMonths[monthName].findIndex((entry) => entry.id === id);
        var newEntry = { ...selectedEntry, title, text };
        newEntryMonths[monthName][entryIndex] = newEntry;

        if (saveTimeout.current) {
            clearTimeout(saveTimeout.current);
            saveTimeout.current = null;
        }
        saveTimeout.current = setTimeout(() => saveEntry(id, title, text, selectedEntry), 10 * 1000);

        setSavingMessage('Waiting to save...');
        updateEntryMonths(newEntryMonths);
    };

    var saveEntry = async (id, title, text, selectedEntry) => {
        setSavingMessage('Saving...');

        var results = await ajax({ endPoint: '/saveEntry', data: { id, title, text } });

        if (results.result) {
            var { monthName } = selectedEntry;
            var entryIndex = entryMonths[monthName].findIndex((entry) => entry.id === id);
            var newEntry = { ...selectedEntry, modifiedOn: new Date(results.modifiedOn), title, text };
            entryMonths[monthName][entryIndex] = newEntry;

            setSavingMessage('Saved!');
            updateEntryMonths(entryMonths);
        } else {
            console.log(results);
            setSavingMessage('There was an error saving.');
        }
    };

    var createNewEntry = async () => {
        var results = await ajax({ endPoint: '/createNewEntry' });

        if (results.result) {
            var createdOn = new Date(results.createdOn)
            var newEntry = {
                id: results.id,
                title: '',
                text: '',
                createdOn,
                modifiedOn: createdOn,
                monthName: dateFormat(createdOn, 'mmm yyyy'),
            };
            var { monthName } = newEntry;
            var newEntryMonths = { ...entryMonths };
            if (newEntryMonths[monthName]) {
                newEntryMonths[monthName].splice(0, 0, newEntry);
            } else {
                newEntryMonths[monthName] = [newEntry];
            }

            setSelected(newEntry.id);
            updateEntryMonths(newEntryMonths);
        } else {
            console.log(results);
        }
    };

    var deleteEntry = async (monthName, entryId) => {
        if (!(await confirmProm('Are you sure you want to delete this entry?'))) {
            return;
        }

        var results = await ajax({ endPoint: '/deleteEntry', data: { entryId } });

        if (results.result) {
            var index = entryMonths[monthName].findIndex((entry) => entry.id === entryId);
            if (index > -1) {
                entryMonths[monthName].splice(index, 1);
                if (!entryMonths[monthName].length) {
                    delete entryMonths[monthName];
                }

                if (selected === entryId) {
                    setSelected(null);
                }
                updateEntryMonths(entryMonths);
            } else {
                console.log('Couldn\'t find deleted index', entryId);
            }
        } else {
            console.log(results);
        }
    };

    var selectedEntry = getSelectedEntry();

    return (
        <div className='home'>
            <div className='mainToolBar'>
                <div></div>
                <div className='newEntryButton' onClick={createNewEntry}>+</div>
                <div className='logoutButton' onClick={logout}>Logout</div>
            </div>
            <EntryList
                deleteEntry={deleteEntry}
                entryMonths={entryMonths}
                selected={selected}
                setSelected={setSelectedEntry}
            />
            <CurrentEntry
                createNewEntry={createNewEntry}
                entry={selectedEntry}
                savingMessage={savingMessage}
                setSelected={setSelectedEntry}
                updateSelectedEntry={updateSelectedEntry}
            />
        </div>
    );
}

export default Home;
