import React, { useEffect, useRef } from 'react';
import dateFormat from 'dateformat';

import './current-entry.scss';

function CurrentEntry(props) {
    var { createNewEntry, entry, savingMessage, setSelected, updateSelectedEntry } = props;

    var titleRef = useRef();
    var textareaRef = useRef();

    useEffect(() => {
        if (entry?.id) {
            titleRef.current.focus();
        }
    }, [entry?.id]);

    var handleTitleKeyUp = (event) => {
        if ([13, 40].includes(event.keyCode)) { // Enter, down arrow keys
            textareaRef.current.focus(); // focus on textarea
            textareaRef.current.setSelectionRange(0, 0); // move cursor to beginning
            textareaRef.current.scrollTop = 0; // scroll to top to show the cursor
        }
    };

    var { createdOn, id, modifiedOn, text, title } = entry || {};

    return (
        <div className='currentEntryContainer'>
            <div className='currentEntryToolBar'>
                <div className='close' onClick={() => setSelected(null)}>
                    {id ? String.fromCharCode(215) : ''}
                </div>
                <div className='date'>
                    {createdOn ? dateFormat(createdOn, 'ddd mmm dS, yyyy, h:MM TT Z') : ''}
                </div>
                <div className='savingMessage'>{savingMessage}</div>
                <div className='lastModified'>
                    {modifiedOn ? 'Last modified on ' + dateFormat(modifiedOn, 'ddd mmm dS, yyyy, h:MM TT Z') : ''}
                </div>
                <div className='menu'>&#8226;&#8226;&#8226;</div>
            </div>
            {!id ? (
                <div className='currentEntryNoEntry' onClick={() => createNewEntry()}>
                    <div>Create New Entry</div>
                </div>
            ) : (
                <div className='currentEntry'>
                    <input
                        className='title'
                        onChange={(event) => updateSelectedEntry(event.target.value, text)}
                        onKeyUp={handleTitleKeyUp}
                        placeholder='Title'
                        ref={titleRef}
                        type='text'
                        value={title || ''}
                    />
                    <textarea
                        className='text'
                        onChange={(event) => updateSelectedEntry(title, event.target.value)}
                        ref={textareaRef}
                        value={text || ''}
                    />
                </div>
            )}
        </div>
    );
}

export default CurrentEntry;
